<template>
<v-card color="#F0FFFF" elevation="0" outlined min-width="250" max-width="250" height="320">
  <v-container fluid>
    <div>
      <div class="d-flex align-center">
        <v-icon @click="prevMonth">mdi-arrow-left-bold</v-icon>
        <v-spacer></v-spacer>
        <div v-if="!!picked" class="text-subtitle-2">{{ picked.year }}年{{ picked.month + 1 }}月</div>
        <v-spacer></v-spacer>
        <v-icon @click="nextMonth">mdi-arrow-right-bold</v-icon>
      </div>
      <div class="d-flex py-1 pl-2">
        <div style="padding-right:18px;" v-for="(day, i) in weekDays">{{ day }}</div>
      </div>
      <div>
        <div v-for="i in Array.from({ length: 6 }, (_, n) => n+1)" :key="i" class="d-flex">
          <div @click="pickDate(dates[(i-1)*7+j-1], j-1)" :class="getColor(dates[(i-1)*7+j-1])" v-for="j in Array.from({ length: 7 }, (_, n) => n+1)" :key="j">{{ dates[(i-1)*7 + j -1] }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-2 justify-center">
      <div class="align-center d-flex">
        <v-avatar size="10" color="#80CBC4"></v-avatar>
        <div class="text-caption mx-2">已交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#FFCDD2"></v-avatar>
        <div class="text-caption mx-2">未交</div>
      </div>
      <div class="align-center d-flex">
        <v-avatar size="10" color="#9575CD"></v-avatar>
        <div class="text-caption mx-2">补交</div>
      </div>
    </div>
  </v-container>
</v-card>
</template>

<script>
import moment from 'moment';
export default {
  name: 'DateCalendar',
  props: ['current', 'actives', 'expireds'],
  data() {
    return {
      weeks: [],
      thisMonday: null,
      picked: null,
      dates: [],
      weekDays: ['日', '一', '二', '三', '四', '五', '六'],
    }
  },
  methods: {
    prevMonth() {
      if (this.picked.month > 0) {
        this.picked.month -= 1;
      } else {
        this.picked.year -= 1;
        this.picked.month = 11;
      }
      this.picked.date = 1;
      this.setDates();
      this.$emit('pick', this.picked);
    },
    nextMonth() {
      if (this.picked.month < 11) {
        this.picked.month += 1;
      } else {
        this.picked.year += 1;
        this.picked.month = 0;
      }
      this.picked.date = 1;
      this.setDates();
      this.$emit('pick', this.picked);
    },
    setDates() {
      const day = new Date(this.picked.year, this.picked.month, 1);
      const firstWeekDay = day.getDay(); // day of week of first date in month
      const daysInMonth = new Date(this.picked.year, this.picked.month + 1, 0).getDate(); // days in month
      this.dates = [];
      for (let i = 0; i < firstWeekDay; i++) {
        this.dates.push('');
      }
      for (let i = 0; i < daysInMonth; i++) {
        if (i < daysInMonth) {
          this.dates.push(i + 1);
        }
      }
    },
    pickDate(date, dayOfWeek) {
      if (!!date) {
        this.picked.date = date;
        this.picked.dayOfWeek = dayOfWeek;
        this.$emit('pick', this.picked);
      }
    },
    getColor(date) {
      if (!!this.picked) {
        const btnDate = new Date(this.picked.year, this.picked.month, date).getTime();
        const today = new Date().getTime();
        if (btnDate > today) {
          return 'calendar-date elevation-1';
        } else if (!!date) {
          const month = `0${this.picked.month + 1}`.slice(-2);
          const oday = `${this.picked.year}-${month}-${date}`;
          const day = `${this.picked.year}-${this.picked.month+1}-${date}`;
          if (this.actives.includes(day) || this.actives.includes(oday)) {
            return 'calendar-date elevation-1 blue lighten-3';
          } else if (this.expireds.includes(day) || this.expireds.includes(oday)) {
            return 'calendar-date elevation-1 purple lighten-3';
          }
          return 'calendar-date elevation-1 red lighten-4';
        }
      }
      return 'calendar-date';
    }
  },
  mounted() {
    this.picked = Object.assign({}, this.current);
    this.setDates();
  },
}
</script>

<style>
.calendar-date {
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 2px;
  padding-top: 3px;
  border: 1px solid #F5FFFA;
  background-color: #F5FFFA;
  cursor: pointer;
}
</style>
